<div *ngIf="initializationState === false" class="spinner-container">
  <mat-spinner value=25 diameter="40"></mat-spinner>
</div>
<div class="coupon-container" *ngIf="initializationState === true">
  <div class="roboto-text page-title">

    <div class="module-activation-indicator">
      <span class="text">Coupons Feature</span>
      <label class="label" >
        <span *ngIf="couponsFeature else enableSpan" class="coupon-feature-span-on">ON</span>
        <ng-template #enableSpan>
          <span class="coupon-feature-span-off">OFF</span>
        </ng-template>
      </label>
      <div class="buttons">
        <ng-container *ngIf="hasWritePermission">
          <button *ngIf="couponsFeature else enable" (click)="toggleCouponsFeature()" class="disable-button" mat-button>
            <span class="roboto-text coupon-text-white">DISABLE COUPON FEATURE</span>
          </button>

          <ng-template #enable>
            <button (click)="toggleCouponsFeature()" class="enable-button" mat-button>
              <span class="roboto-text coupon-text">ENABLE COUPON FEATURE</span>
            </button>
          </ng-template>
        </ng-container>
      </div>
    </div>



  </div>

  <div *ngIf="hasWritePermission" class="button-details">
    <button id="addCoupon" class="green-button" (click)="addCoupon()">
      <span class="roboto-text coupon-text">CREATE NEW COUPON</span>
    </button>

  </div>


  <div *ngIf="hasReadPermission">
    <nav mat-tab-nav-bar>
      <ng-container *ngFor="let link of navLinks" >
        <a mat-tab-link (click)="changeNavigation(link.archiveState)" [routerLink]="[link.link]" [ngClass]="{'tab-selected': activeLink===link.link}">
          <span>{{link.label}}</span>
        </a>
      </ng-container>
    </nav>

   
    <app-list-coupons 
    (archivedStateChanged)="changeNavigation($event)"
    [archivedState]="archiveState"
    [coupons$]="coupons$"
    [hasReadPermission]="hasReadPermission"
    [hasWritePermission]="hasWritePermission"
    ></app-list-coupons>
  </div>
</div>
