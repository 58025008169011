import { Component, Injector, OnInit } from '@angular/core';
import { AdminWhiteListingProductsProvider } from '@modeso/dgoods-lib-admin-fe';
import { take } from 'rxjs/operators';
import { Subject} from 'rxjs';
import Debug  from 'debug';
import { UserRoles } from '../../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../../shared/util/permission.helper';
import { BasePageComponent } from '../../base.page';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { ProductsWithAvailablePricesDto } from '@modeso/types__dgoods-products';
import { ActivatedRoute, Router } from '@angular/router';

const debug = Debug('modeso:dgoods-admin:AddCouponPage');

@Component({
  selector: 'app-page-coupons',
  templateUrl: './add-coupon.page.html',
  styleUrls: ['./add-coupon.page.scss'],
})
export class AddCouponPage extends BasePageComponent implements OnInit {


  whiteListedProducts = [];
  couponId:string;
  productsSubject: Subject<any> = new Subject<any>();


  constructor(injector: Injector,
              private adminWhiteListingProvider: AdminWhiteListingProductsProvider,
              private activatedRouter: ActivatedRoute,
              private router: Router,
              private localStorageService: LocalStorageService,) {
    super(injector);
    this.couponId = this.activatedRouter.snapshot.params['couponId'];
  }



  ngOnInit() {
    super.ngOnInit();
    this.getWhiteListedProducts();
  }

  hasReadPermission(): boolean {
    const role = this.localStorageService.getUserRole();
    const priviledgedRoles = [
        UserRoles.DIGITAL_VAUCHERS_ADMIN,
        UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
        UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
    ];
    return PermissionHelper.hasPermission(role, priviledgedRoles);
  }

  hasWritePermission(): boolean {
      const role = this.localStorageService.getUserRole();
      const priviledgedRoles = [
        UserRoles.DIGITAL_VAUCHERS_ADMIN,
        UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
        UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
      ];
      return PermissionHelper.hasPermission(role, priviledgedRoles);
  }

  getWhiteListedProducts(){
    this.whiteListedProducts = [];

    this.adminWhiteListingProvider.getAllProductsInAllLanguages$()
    .pipe(take(2)).subscribe((allProducts: ProductsWithAvailablePricesDto[]) => {
        if (allProducts) {

          this.whiteListedProducts = [...allProducts];
          if(this.whiteListedProducts.length !== 0){
            this.productsSubject.next(this.whiteListedProducts);
          }
        }
    })
  }

  navigateToCouponList(){
    this.router.navigate([`/de-ch/coupons-management`])
  }

}
