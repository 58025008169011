import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TwintCouponConfigService } from '..';
import { Observable } from 'rxjs';
import { CouponDTO } from '@modeso/types__twint-lib-coupons';

@Injectable({
  providedIn: 'root',
})
export class TwintCouponsService {
  private server = '';

  private microserviceName = 'cart';
  private readonly addCoupon = '/coupons';
  private readonly editCoupon = '/coupons/';
  private readonly couponFeature = '/coupons/state';
  private readonly activateCoupon = '/activate';
  private readonly deactivateCoupon = '/deactivate';
  private readonly invalidateCode = '/codes/';
  private readonly invalidateCodes = '/codes';
  private readonly archiveCoupon = '/archive';
  private readonly fetchCouponDetailsCoupon = '/details';


  constructor(private http: HttpClient, @Inject(TwintCouponConfigService) private config) {
    this.server = config.apiGateway;
  }

  public createCoupon(coupon: CouponDTO): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.addCoupon}`;
    return this.http.post<CouponDTO>(url, coupon);
  }

  public updateCoupon(coupon: CouponDTO): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${coupon.id}`;
    return this.http.put<CouponDTO>(url, coupon);
  }

  public getCoupons(): Observable<CouponDTO[]> {
    const url = `${this.server}${this.microserviceName}${this.addCoupon}`;
    return this.http.get<CouponDTO[]>(url);
  }

  public fetchCouponDetails(couponId: string): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.fetchCouponDetailsCoupon}`;
    return this.http.get<CouponDTO>(url, {});
  }

  public toggleCouponsFeature(couponFeature: boolean) {
    const url = `${this.server}${this.microserviceName}${this.couponFeature}`;
    return this.http.post<boolean>(url, { state: couponFeature ? 'active' : 'inactive' });
  }
  public checkCouponsFeature() {
    const url = `${this.server}${this.microserviceName}${this.couponFeature}`;
    const res = this.http.get<boolean>(url);
    return res;
  }

  public activateCouponState(couponId: string): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.activateCoupon}`;
    return this.http.post<CouponDTO>(url, {});
  }

  public deactivateCouponState(couponId: string): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.deactivateCoupon}`;
    return this.http.post<CouponDTO>(url, {});
  }

  public deleteCoupon(couponId: string): Observable<any> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}`;
    return this.http.delete<any>(url);
  }

  public invalidateCodeState(invalidateCodeObj): Observable<any> {
    const { couponId, codeId } = invalidateCodeObj;
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.invalidateCode}${codeId}`;
    return this.http.put<any>(url, {});
  }

  public invalidateCodesState(couponId: string): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.invalidateCodes}`;
    return this.http.put<CouponDTO>(url, {});
  }

  public archiveCouponState(archiveCouponObj): Observable<CouponDTO> {
    const url = `${this.server}${this.microserviceName}${this.editCoupon}${archiveCouponObj.couponId}${this.archiveCoupon}`;
    return this.http.post<CouponDTO>(url, {archiveState:archiveCouponObj.archiveState});
  }

}
