import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import Debug from 'debug';
import * as fromFeature from '../reducers/twint.coupons.reducer';
import * as fromActions from '../actions/twint.coupons.actions';
import { CouponDTO } from '@modeso/types__twint-lib-coupons';

const debug = Debug('twint:coupons:TwintCouponsProvider');

@Injectable({
  providedIn: 'root',
})
export class TwintCouponsProvider {
  constructor(public store: Store<fromFeature.AppState>) {}

  public createCoupon(body: CouponDTO) {
    this.store.dispatch(fromActions.onAddCoupon({ payload: body }));
  }

  public editCoupon(body: CouponDTO) {
    this.store.dispatch(fromActions.onUpdateCoupon({ payload: body }));
  }

  public getSelectedCoupon$(couponId): Observable<CouponDTO> {
    this.store.dispatch(fromActions.fetchCouponDetailsById({ payload: couponId }));
    return this.store.pipe(select(fromFeature.selectCouponById(couponId)));
  }

  public getCoupons$(): Observable<CouponDTO[]> {
    return this.store.pipe(select(fromFeature.selectFeatureCoupons));
  }

  public fetchCoupons() {
    this.store.dispatch(fromActions.onGetCoupons());
  }

  public getCouponError$(): Observable<any> {
    return this.store.pipe(select(fromFeature.selectFeatureCouponsError));
  }

  public flushError(): any {
    this.store.dispatch(fromActions.flushCouponError());
  }

  public deleteCoupon(couponId: string): any {
    this.store.dispatch(fromActions.onDeleteCoupon({ payload: couponId }));
  }

  public deactivateCoupon(couponId: string): any {
    this.store.dispatch(fromActions.onDeactivateCoupon({ payload: couponId }));
  }

  public activateCoupon(couponId: string): any {
    this.store.dispatch(fromActions.onActivateCoupon({ payload: couponId }));
  }

  public toggleCouponsFeature(couponFeature: boolean) {
    this.store.dispatch(fromActions.onToggleCouponsFeature({ payload: couponFeature }));
  }
  public checkCouponsFeature() {
    this.store.dispatch(fromActions.onCheckCouponsFeature());
  }
  public getCouponsFeature$() {
    return this.store.pipe(select(fromFeature.selectFeatureCouponsFeature));
  }
  public getCouponsFeatureInitializationState$() {
    return this.store.pipe(select(fromFeature.selectFeatureCouponsInitialized));
  }
  public invalidateCode(invalidateCodeObj): any {
    this.store.dispatch(fromActions.onInvalidateCode(invalidateCodeObj));
  }

  public invalidateCodes(couponId: string) {
    this.store.dispatch(fromActions.onInvalidateCodes({ payload: couponId }));
  }

  public archiveCoupon(archiveCouponObj): any {
    this.store.dispatch(fromActions.onArchiveCoupon({ payload: archiveCouponObj }));
  }
}
