import { Component, Injector, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Debug from 'debug';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { CouponDTO } from '@modeso/types__twint-lib-coupons';
import { Output, EventEmitter } from '@angular/core';
import { TwintCouponsProvider } from '../../providers/twint.coupons.provider';
import { ConfirmFeatureDialog } from '../../dialog/confirmFeatureDialog/confirmFeatureDialog';

const debug = Debug('modeso:twint-admin:campaigns');

@Component({
  selector: 'app-page-manage-coupons',
  templateUrl: './manage-coupons.page.html',
  styleUrls: ['./manage-coupons.page.scss'],
})
export class ManageCouponsPage implements OnDestroy {
  dataSource = new MatTableDataSource<CouponDTO>();
  coupons$: Observable<any>;
  couponsFeature: boolean;
  couponsFeature$: Observable<boolean>;
  initializationState$: Observable<boolean>;
  initializationState: boolean = false;
  navLinks;
  activeLink;
  archiveState;

  @Input() hasReadPermission = false;
  @Input() hasWritePermission = false;
  @Output() addCouponClick = new EventEmitter<string>();
  protected subscriptions = [];

  constructor(injector: Injector, private couponProvider: TwintCouponsProvider, public dialog: MatDialog) {
    // dispatch fetching coupons action

    this.checkCouponsFeature();

    // get coupons from store as observable and map it to set paginator
    this.coupons$ = this.couponProvider.getCoupons$();
    this.couponsFeature$ = this.getCouponsFeature();
    this.couponProvider.fetchCoupons();
    this.initializationState$ = this.couponProvider.getCouponsFeatureInitializationState$();
    this.subscriptions.push(
      this.couponsFeature$.subscribe((state) => {
        this.couponsFeature = state;
      })
    );
    this.subscriptions.push(
      this.initializationState$.subscribe((state) => {
        setTimeout(() => {
          this.initializationState = state;
        }, 250);
      })
    );
  }

  ngOnInit() {
      this.populateNavLinks();
  }


  addCoupon() {
    this.addCouponClick.emit('OpenAddCoupon');
  }

  toggleCouponsFeature() {
    const dialogRef = this.dialog.open(ConfirmFeatureDialog, {
      data: {
        activate: !this.couponsFeature,
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.couponProvider.toggleCouponsFeature(!this.couponsFeature);
        }
      })
    );
  }

  checkCouponsFeature() {
    return this.couponProvider.checkCouponsFeature();
  }
  getCouponsFeature() {
    return this.couponProvider.getCouponsFeature$();
  }

  populateNavLinks() {
    this.navLinks = [
      {
          label: 'Coupons',
          link: 'all',
          index: 0,
          archiveState: false

      }
      , {
          label: 'Archived Coupons',
          link: 'archieved',
          index: 1,
          archiveState: true
      },
    ];
    this.activeLink = this.navLinks[0].link;
    this.archiveState = this.navLinks[0].archiveState;
  }

  changeNavigation(archiveState){
    this.activeLink = this.navLinks.find((link)=> link.archiveState === archiveState).link;
    this.archiveState = archiveState
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }
}
