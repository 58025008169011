<div class="coupon-container" *ngIf="couponManagement$ | async as coupons" >
  <div [hidden]="coupons.data.length === 0">
    <table  mat-table [dataSource]="coupons" class="mat-table">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
            Name
        </th>
        <td mat-cell *matCellDef="let coupon">
          <span>
            {{coupon.name}}
          </span>
        </td>
      </ng-container>


      <ng-container matColumnDef="validFrom">
        <th mat-header-cell *matHeaderCellDef>
            Valid From
        </th>
        <td mat-cell *matCellDef="let coupon">
          <span>
            {{coupon.startFrom | date:'dd.MM.y HH:mm:ss'}}
          </span>
        </td>
      </ng-container>


      <ng-container matColumnDef="validTo">
        <th mat-header-cell *matHeaderCellDef>
            Valid To
        </th>
        <td mat-cell *matCellDef="let coupon">
          <span>
            {{coupon.endAt | date:'dd.MM.y HH:mm:ss'}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="numberOfAvailableCoupons">
        <th mat-header-cell *matHeaderCellDef>
          Nr. of Created Coupons
        </th>
        <td mat-cell *matCellDef="let coupon">
          <span>
            {{coupon.availableCodes}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="maxApplicationPerUser">
        <th mat-header-cell *matHeaderCellDef>
            Max Use Per User
        </th>
        <td mat-cell *matCellDef="let coupon">
          <span>
            {{coupon.maxApplicationPerUser}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="redeemState">
        <th mat-header-cell *matHeaderCellDef>
          Redeem State
        </th>
        <td mat-cell *matCellDef="let coupon">
          <span>
            {{coupon.redeemState}}
          </span>
        </td>
      </ng-container>


      <ng-container matColumnDef="activationState">
        <th mat-header-cell *matHeaderCellDef>
          Activation State
        </th>
        <td mat-cell *matCellDef="let coupon">
          <span>
            {{coupon.state}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="buttons" >
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="actions" mat-cell *matCellDef="let coupon">
          <button *ngIf="coupon.state === couponActivationState.Active && hasWritePermission" class="states activate-button" (click)="changeCouponState(coupon)">Disable</button>
          <button *ngIf="coupon.state === couponActivationState.Inactive && archivedState !== true &&  hasWritePermission" class="states activate-button" (click)="changeCouponState(coupon)">Activate</button>
          <button *ngIf="hasWritePermission" class="states edit-button" (click)="editCoupon(coupon.id)">Edit</button>
          <button *ngIf="hasReadPermission && !hasWritePermission" class="states edit-button" (click)="editCoupon(coupon.id)">View</button>
          <span *ngIf="hasWritePermission">
            <button mat-button [matMenuTriggerFor]="menu" class="menu-button">
              <mat-icon class="mat-icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="archivedState !== true" mat-menu-item  (click)="archiveCoupon(coupon.id,coupon.archived)">Archive</button>
              <button *ngIf="archivedState === true" mat-menu-item (click)="archiveCoupon(coupon.id,coupon.archived)">Unarchive</button>
              <button *ngIf="archivedState === true" mat-menu-item [disabled]="coupon.state === couponActivationState.Active" (click)="deleteCoupon(coupon)">Delete</button>
            </mat-menu>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator   #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

  </div>

  <div [hidden]="coupons.data.length !== 0">
    <span  *ngIf="archivedState !== true">There are no campaigns yet.</span>
  </div>
</div>
