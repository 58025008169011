/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular-material-components/moment-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import moment from 'moment';
import Debug  from 'debug';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { couponInitValue } from './coupon';
import { TwintCouponsProvider } from '../../providers/twint.coupons.provider';
import { CouponDTO, CouponEffectTypeEnum, CouponTypeEnum , CouponCodeDTO, CouponJournalEnum, RedeemStateEnum} from '@modeso/types__twint-lib-coupons';
import { Observable, Subscription, take } from 'rxjs';
import { CUSTOM_DATE_FORMATS, CUSTOM_MOMENT_FORMATS } from '../..//util/dateFormats.helper';
import { CouponErrorDialog } from '../../dialog/errorDialog/errorDialog';
import { ConfirmDeletionDialog } from '../../dialog/confirmDeletionDialog/confirmDeletion';
import { ConfirmationDialog } from '../../dialog/confirmationDialog/confirmationDialog';
import { lastValueFrom } from 'rxjs';
import { CouponDTOExtended } from '../list-coupons/list-coupons.component';

const debug = Debug('modeso:dgoods-admin:AddCouponPage');
const MAX100_Validator = Validators.max(100.00)
const MAX500_Validator = Validators.max(500.00)
@Component({
  selector: 'app-add-coupon-component',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss'],
  providers:[
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS},
    {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ],
})
export class AddCouponComponent {

  @Input() couponId;
  @Input() productsObservable$: Observable<any>;
  @ViewChild('allProductsSelected') private allProductsSelected: MatOption;
  @Output() redirectToList: EventEmitter<any>  = new EventEmitter();

  couponForm: FormGroup;
  showSeconds = false;
  defaultTime = [0, 0];
  isEditMode = false;
  isViewMode = false;
  isCouponRedeemed = false;
  isFormSumitted = false;
  error = '';
  autoGeneratedCodes: CouponCodeDTO[] = [];
  showInvalidateCodesBtn = false;
  whiteListedProducts = [];
  getCoupon: Subscription;
  getErrors: Subscription;
  productsSubscription: Subscription;
  redeemState: RedeemStateEnum = RedeemStateEnum.OPEN;
  availableCodes: number = -1;
  constructor(private formBuilder: FormBuilder,
              private couponProvider: TwintCouponsProvider,
              public dialog: MatDialog) {}

  ngOnInit(){

    this.getErrors = this.couponProvider.getCouponError$().subscribe((httperror) => {
      debug("errors...")
      debug(httperror)
      if (httperror !== null) {
        debug(httperror)
        if(httperror && httperror.error && httperror.error.message ){
          this.isFormSumitted = false;
          //Show dialog
          this.dialog.open(CouponErrorDialog, {
            data: {
              error: httperror.error.message
            },
          });
        } else {
          if(httperror && httperror.error ){
            //Show general dialoge
            this.dialog.open( CouponErrorDialog, {
              data: {
                error: "Internal Server error"
              },
            });
          }
        }

      }
    });

    if (this.couponId) {
      this.isEditMode = true;
      this.getCoupon = this.couponProvider.getSelectedCoupon$(this.couponId).subscribe((coupon)=>{

        debug(coupon);
      // coupon is found in store
      if (coupon) {

        this.redeemState = this.calculateRedeemState(coupon);
        this.availableCodes = this.calculateAvailableCodes(coupon);
        this.initCouponForm(coupon);
        this.isCouponRedeemed=this.checkCouponRedeemedState(coupon)
        this.disableFieldsAccordingToType(coupon.couponType);

        if(coupon.products.length == 0) {
          this.products.patchValue([0]);

        }
      } else {
        //coupons come from BE
        this.couponProvider.fetchCoupons();
      }
      })

    } else {
      this.initCouponForm(couponInitValue);
    }

    this.productsSubscription = this.productsObservable$?.subscribe((products) => {

      if(products){

        this.whiteListedProducts = [...products];
        // initially add all products to coupon products array
        if(!this.couponId || this.whiteListedProducts.length === this.products?.value.length){
          // patch a '0' value for 'ALL' selection
          this.products.patchValue([0]);
        }
      }
    });
  }


  initCouponForm(couponInit){
    const coupon = {...couponInit};
    this.couponForm = this.formBuilder.group({
      name: new FormControl(coupon.name, [Validators.required, Validators.minLength(3), Validators.maxLength(50) ]),
      couponType: new FormControl(coupon.couponType ,[Validators.required]),
      state : new FormControl(coupon.state),
      // to be changed after auto generation imp
      manualCodes: new FormControl(coupon.codes[0]?.code,[Validators.required, Validators.pattern('^[a-zA-Z0-9]*$'), Validators.minLength(6), Validators.maxLength(20)]),
      noOfCodes: new FormControl(coupon.codes?.length !== 0 ? coupon.codes.length : '',[Validators.max(100000),Validators.min(1)]),
      effectType: new FormControl(coupon.effectType,[Validators.required]),
      effectValue: new FormControl(coupon.effectValue,[ Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$'),Validators.min(0.01),Validators.required]),
      products: new FormControl(coupon.products),
      minPurchaseAmount: new FormControl(coupon.minPurchaseAmount,[Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$'),Validators.min(0.00),Validators.max(9999.99), Validators.required]),
      maxApplicationPerUser: new FormControl(coupon.maxApplicationPerUser,[Validators.min(0), Validators.max(9999999999), Validators.required]),
      maxApplicationOverAllUsers: new FormControl(coupon.maxApplicationOverAllUsers,[Validators.min(0), Validators.max(9999999999), Validators.required]),
      startFrom: new FormControl(coupon.startFrom, Validators.required),
      endAt: new FormControl(coupon.endAt, Validators.required),
    });
    this.changeCouponEffectType(coupon.effectType,true);

    // Define a custom validator that compares the values of the FormControls
    function validateFields(form: FormGroup) {
      const minPurchaseAmount = form.get('minPurchaseAmount').value;
      const effectValue = form.get('effectValue').value;
      const effectType = form.get('effectType').value;
      const startFrom = form.get('startFrom').value;
      const endAt = form.get('endAt').value;

      let invalidDates = false;
      if (startFrom && endAt) {
        if (!moment(endAt).isAfter(moment(startFrom))) {

          invalidDates = true;
        }
        if (moment(endAt).isAfter(moment("2032-12-31"))) {
          invalidDates = true;
        }
        if (moment(startFrom).isAfter(moment("2032-12-31"))) {

          invalidDates = true;
        }
        if (moment(startFrom).isBefore(moment("2022-12-31"))) {

          invalidDates = true;
        }
      }

      if (effectType === CouponEffectTypeEnum.Fixed) {
        // Compare the values of the fields and return an error if necessary
        if (minPurchaseAmount < effectValue) {
          return { invalidDates, minPurchaseAmountToSmall: true };
        }
      }

      if (invalidDates) {
        return { invalidDates }
      }
      return null

    }

    this.couponForm.setValidators(Validators.compose([validateFields]));
    if(this.couponType.value === CouponTypeEnum.Multi && this.isEditMode){
      this.autoGeneratedCodes = [...coupon.codes];
      this.showInvalidateCodesBtn = this.autoGeneratedCodes.filter((code)=> code.state === CouponJournalEnum.CREATED || code.state === CouponJournalEnum.CANCELED).length !== 0 ? true : false;
    }

  }

  async confirmSubmit(){
    let data;

    let dialogText = "";

    if(this.noOfCodes.valid && this.noOfCodes.value > 500 && !this.isEditMode){
      dialogText = `You have selected to generate ${this.noOfCodes.value} codes. Once the codes are generated this field will no longer be editable.<br>`;
    }
    if(this.effectType.value === CouponEffectTypeEnum.Percentage && this.effectValue.value > 10){
      dialogText = `${dialogText}You have selected ${this.effectValue.value}% as a discount value.<br>`;
    }


    if(this.effectType.value === CouponEffectTypeEnum.Fixed && this.effectValue.value > 10){
      dialogText = `${dialogText}<br>You have selected CHF ${this.effectValue.value} as a discount price amount.<br>`;
    }

    if(dialogText !== "") {

      dialogText = `${dialogText}<br><br>Are you sure?`
      data = {
        text: dialogText,
        type: 1
      }
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data
      });
      const result = await lastValueFrom(dialogRef.afterClosed().pipe(take(1)));
      if (!result) return;
    }
    this.submit();
  }

  submit(){

    this.error = undefined;
    this.isFormSumitted = true;

    let coupon = {
      name: this.name?.value,
      couponType: this.couponType?.value,
      state: this.state.value,
      // remove the 'All' from products selection menu
      products: this.products?.value.filter(product=> product !== 0),
      minPurchaseAmount: this.minPurchaseAmount?.value,
      startFrom: this.startFrom?.value,
      endAt: this.endAt?.value,
      effectType: this.effectType?.value,
      effectValue:  this.effectValue.value,
      maxApplicationOverAllUsers: this.maxApplicationOverAllUsers?.value,
      maxApplicationPerUser:this.maxApplicationPerUser?.value
    } as CouponDTO;

    switch(this.couponType.value){
      case CouponTypeEnum.Single:
        coupon.codes = [this.manualCodes.value.toLowerCase()];
      break;

      case CouponTypeEnum.Multi:
        coupon.noOfCodes = this.noOfCodes?.value
      break;
    }

    if (this.couponId) {
      coupon.id = this.couponId;
      if(!this.isViewMode){
        this.couponProvider.editCoupon(coupon);
      }else{
        this.navigateBackToList();
      }

    }else{
      this.couponProvider.createCoupon(coupon);
    }

  }

  toggleAllProductSelection() {
    if (this.allProductsSelected.selected) {
      this.products
        .patchValue([0]);
    } else {
      this.products.patchValue([...this.products.value]);
    }
  }

  unselectAllProductsIfNeeded(){
    if (this.allProductsSelected.selected) {
     this.allProductsSelected.deselect();
    }
  }

  changeCouponEffectType(couponEffectType,onInit=true){
    // remove the effect value in case of toggling effect type only in add mode
    if(!onInit && !this.couponId && this.effectValue.value){
      this.effectValue.setValue("")
    }
    if (couponEffectType === CouponEffectTypeEnum.Fixed) {
      this.effectValue.removeValidators(MAX100_Validator)
      this.effectValue.addValidators(MAX500_Validator);

    } else {
      // clear validator
      this.effectValue.removeValidators(MAX500_Validator)
      this.effectValue.addValidators(MAX100_Validator);
    }
    this.effectValue?.updateValueAndValidity();
  }

  confirmInputValue(control:string){

    let data;

    switch(control){

      case 'generatedCodes':
        data = {
          text: `You have selected to generate ${this.noOfCodes.value} codes. Once the codes are generated this field will no longer be editable. <br><br> Are you sure?`,
          type: 2
        }
        if(this.noOfCodes.valid && !this.isEditMode){
          this.dialog.open(ConfirmationDialog, {
            data
          });
        }
        break;

      case 'discount':

        break;

      case 'fixedAmount':

        break;

      default:
        break;
    }

  }

  changeCouponType(couponType){
   if(couponType === CouponTypeEnum.Multi){
    this.maxApplicationOverAllUsers.setValue(1);
    this.maxApplicationPerUser.setValue(1);
    this.noOfCodes.addValidators(Validators.required)
    this.manualCodes.removeValidators(Validators.required)
    this.maxApplicationOverAllUsers.disable();
    this.maxApplicationPerUser.disable();

   }else{
    this.maxApplicationOverAllUsers.setValue(1)
    this.maxApplicationPerUser.setValue(0)
    this.noOfCodes.removeValidators(Validators.required)
    this.manualCodes.addValidators(Validators.required)
    this.maxApplicationPerUser.enable();
    this.maxApplicationOverAllUsers.enable();

   }
   this.manualCodes.updateValueAndValidity();
   this.noOfCodes.updateValueAndValidity();
  }

  invalidateCode(code){
    const invalidateCodeObj = {
      couponId: this.couponId,
      codeId: code
    }
    this.couponProvider.invalidateCode(invalidateCodeObj);
  }

  invalidateCodes(){

    const dialogRef = this.dialog.open(ConfirmDeletionDialog, {
      data: {
          message: `Are you sure you want to invalidate all codes?`
      },
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
      if (result) {
         this.couponProvider.invalidateCodes(this.couponId);
      }
    });
  }

  downloadCodes(){
    const fileName = "Campaign_" + this.name.value + `_codes_${moment().format('YYYYMMDDHHmmss')}.xlsx`;
    const mappedCodes = this.autoGeneratedCodes.map((code:any)=> {
      let codeMap = {...code}
      codeMap.state = this.couponJournalEnumMapper[code.state].toLowerCase()
      return codeMap;
    })
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedCodes);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'test');
		XLSX.writeFile(wb, fileName);
  }

  navigateBackToList(){
    this.redirectToList.emit();
  }

  checkCouponRedeemedState(coupon): boolean{

    if (coupon.couponType === CouponTypeEnum.Multi) {
      return  coupon.codes.length === coupon.usedCodes ? true : false;
    }

    if (coupon.couponType === CouponTypeEnum.Single) {
      if (coupon.maxApplicationOverAllUsers === 0) {
        return false;
      } else {
        return coupon.maxApplicationOverAllUsers <= coupon.usedCodes ? true: false;
      }
    }

    return false;

  }

  disableFieldsAccordingToType(couponType){

    if(this.isEditMode){
      this.couponType.disable();
      this.manualCodes.disable();
      this.noOfCodes.disable();
      this.effectType.disable();

      if (couponType === CouponTypeEnum.Multi) {
        this.maxApplicationPerUser.disable();
        this.maxApplicationOverAllUsers.disable();
      }

      if(this.isCouponRedeemed){
        this.effectValue.disable();
        this.minPurchaseAmount.disable();
        this.maxApplicationPerUser.disable();
        this.maxApplicationOverAllUsers.disable();
        this.startFrom.disable();
        this.endAt.disable();
      }
    }
  }

  get name() {
    return this.couponForm.get('name');
  }
  get couponType() {
    return this.couponForm.get('couponType');
  }
  get manualCodes() {
    return this.couponForm.get('manualCodes');
  }
  get noOfCodes() {
    return this.couponForm.get('noOfCodes');
  }
  get effectType() {
    return this.couponForm.get('effectType');
  }
  get effectValue() {
    return this.couponForm.get('effectValue');
  }
  get products() {
    return this.couponForm?.get('products');
  }
  get minPurchaseAmount() {
    return this.couponForm.get('minPurchaseAmount');
  }
  get maxApplicationPerUser() {
    return this.couponForm.get('maxApplicationPerUser');
  }
  get maxApplicationOverAllUsers() {
    return this.couponForm.get('maxApplicationOverAllUsers');
  }
  get startFrom() {
    return this.couponForm.get('startFrom');
  }
  get endAt() {
    return this.couponForm.get('endAt');
  }
  get state() {
    return this.couponForm.get('state');
  }

  public get couponTypes(): typeof CouponTypeEnum {
    return CouponTypeEnum;
  }

  public get couponEffectTypes(): typeof CouponEffectTypeEnum {
    return CouponEffectTypeEnum;
  }

  public get couponJournalEnum(): typeof CouponJournalEnum {
    return CouponJournalEnum;
  }
  public get couponJournalEnumMapper(): typeof CouponJournalEnum {
    let journalEnum = {...CouponJournalEnum };
    journalEnum[journalEnum.CANCELED] = 'PAYMENT CANCELLED';
    return journalEnum;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(){
    if(this.getCoupon){
      this.getCoupon.unsubscribe();
    }
    if(this.getErrors){
      this.getErrors.unsubscribe();
      this.couponProvider.flushError();
    }

    if(this.productsSubscription){
      this.productsSubscription.unsubscribe();
    }
  }

  calculateRedeemState(coupon: CouponDTOExtended) {
    let redeemState;

    if (coupon.couponType === CouponTypeEnum.Multi) {
      redeemState = coupon.codes.length <= coupon.usedCodes
      ? RedeemStateEnum[RedeemStateEnum.REDEEMED]
      : RedeemStateEnum[RedeemStateEnum.OPEN];

    }
    else if (coupon.couponType === CouponTypeEnum.Single) {
      if (coupon.maxApplicationOverAllUsers === 0) {
        redeemState = RedeemStateEnum[RedeemStateEnum.OPEN];

      } else {
        redeemState = coupon.maxApplicationOverAllUsers <= coupon.usedCodes
        ? RedeemStateEnum[RedeemStateEnum.REDEEMED]
        : RedeemStateEnum[RedeemStateEnum.OPEN];

      }
    }
    return redeemState;
  }

  calculateAvailableCodes(coupon: CouponDTOExtended) {
    let countTotal;

    if (coupon.couponType == CouponTypeEnum.Single) {
        if (coupon.maxApplicationOverAllUsers == 0) {
          return null;
        }
        else {
          countTotal = coupon.maxApplicationOverAllUsers;
        }

    }else if (coupon.couponType == CouponTypeEnum.Multi) {
        countTotal = coupon.codes.length;

      }
    const countAvailable = countTotal - coupon.usedCodes;
    return countAvailable;
  }
}
