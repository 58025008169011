import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from '../actions/twint.coupons.actions';
export const featureKey = 'twintCouponModule';
import Debug from 'debug';
import { HttpErrorResponse } from '@angular/common/http';
import { CouponDTO } from '@modeso/types__twint-lib-coupons';
const debug = Debug('twint:coupons:Reducer');
// State Declarations - START

export interface FeatureState {
  coupons: CouponDTO[];
  couponsError: string | HttpErrorResponse;
  couponsFeature: boolean;
  initlized: boolean;
}

export interface AppState {
  twintCouponModule: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.twintCouponModule;

export const selectFeatureCoupons = createSelector(selectFeature, (state: FeatureState) => state.coupons);

export const selectCouponById = (couponId: string) =>
  createSelector(selectFeature, (state: FeatureState) => state.coupons.find((coupon) => coupon.id === couponId));

export const selectFeatureCouponsError = createSelector(selectFeature, (state: FeatureState) => state.couponsError);
export const selectFeatureCouponsFeature = createSelector(selectFeature, (state: FeatureState) => state.couponsFeature);
export const selectFeatureCouponsInitialized = createSelector(selectFeature, (state: FeatureState) => state.initlized);

// Selectors Declarations - END

// Reducer Declarations - START
export const initialState: FeatureState = {
  coupons: [],
  couponsError: undefined,
  couponsFeature: false,
  initlized: false,
};

const couponsReducer = createReducer(
  initialState,

  // add coupon
  on(fromActions.onAddCoupon, (state) => ({ ...state, couponsError: undefined })),
  on(fromActions.onAddCouponSuccessfully, (state, action) => ({ ...state, couponsError: undefined })),
  on(fromActions.onAddCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // edit coupon
  on(fromActions.onUpdateCoupon, (state) => ({ ...state })),
  on(fromActions.onUpdateCouponSuccessfully, (state, action) => ({ ...state, couponsError: undefined })),
  on(fromActions.onUpdateCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // get all coupons
  on(fromActions.onGetCoupons, (state) => ({
    ...state,
    coupons: [],
  })),
  on(fromActions.onGetCouponsSuccessfully, (state, action) => {
    return { ...state, coupons: action.payload, couponsError: undefined };
  }),
  on(fromActions.onGetCouponsFailed, (state, action) => {
    return { ...state, couponsError: action.payload };
  }),

  // ToggleCouponsFeature
  on(fromActions.onToggleCouponsFeature, (state) => ({ ...state, couponsError: undefined })),
  on(fromActions.onToggleCouponsFeatureSuccessfully, (state, action) => {
    return { ...state, couponsFeature: action.payload, couponsError: undefined };
  }),
  on(fromActions.onToggleCouponsFeatureFailed, (state, action) => {
    return { ...state, couponsError: action.payload };
  }),
  // CheckCouponsFeature
  on(fromActions.onCheckCouponsFeature, (state) => ({ ...state, couponsError: undefined })),
  on(fromActions.onCheckCouponsFeatureSuccessfully, (state, action) => {
    return { ...state, couponsFeature: action.payload, couponsError: undefined, initlized: true };
  }),
  on(fromActions.onCheckCouponsFeatureFailed, (state, action) => {
    return { ...state, couponsError: action.payload, initlized: true };
  }),

  // activate coupon
  on(fromActions.onActivateCoupon, (state) => ({ ...state })),
  on(fromActions.onActivateCouponSuccessfully, (state, action) => {
    const payload = action.payload;
    const updatedCoupons = [...state.coupons];
    const updatedIndex = updatedCoupons.findIndex((coupon) => coupon.id === payload.id);

    updatedIndex === -1 ? updatedCoupons.push({ ...payload }) : (updatedCoupons[updatedIndex] = payload);

    return { ...state, coupons: [...updatedCoupons], couponsError: undefined };
  }),
  on(fromActions.onActivateCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // deactivate coupon
  on(fromActions.onDeactivateCoupon, (state) => ({ ...state })),
  on(fromActions.onDeactivateCouponSuccessfully, (state, action) => {
    const updatedCoupons = [...state.coupons];
    const updatedIndex = updatedCoupons.findIndex((coupon) => coupon.id === action.payload.id);

    updatedIndex === -1 ? updatedCoupons.push({ ...action.payload }) : (updatedCoupons[updatedIndex] = action.payload);

    return { ...state, coupons: [...updatedCoupons], couponsError: undefined };
  }),
  on(fromActions.onDeactivateCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // delete coupon
  on(fromActions.onDeleteCoupon, (state) => ({ ...state })),
  on(fromActions.onDeleteCouponSuccessfully, (state, action) => {
    const updatedCoupons = [...state.coupons];
    const updatedIndex = updatedCoupons.findIndex((coupon) => coupon.id === action.payload);
    updatedCoupons.splice(updatedIndex, 1);
    return { ...state, coupons: [...updatedCoupons], couponsError: undefined };
  }),
  on(fromActions.onDeleteCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // invalidate code
  on(fromActions.onInvalidateCode, (state) => ({ ...state })),
  on(fromActions.onInvalidateCodeSuccessfully, (state, action) => {
    const updatedCoupons = [...state.coupons];
    const updatedIndex = updatedCoupons.findIndex((coupon) => coupon.id === action.payload.id);

    updatedIndex === -1 ? updatedCoupons.push({ ...action.payload }) : (updatedCoupons[updatedIndex] = action.payload);

    return { ...state, coupons: [...updatedCoupons], couponsError: undefined };
  }),
  on(fromActions.onInvalidateCodeFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // invalidate codes
  on(fromActions.onInvalidateCodes, (state) => ({ ...state })),
  on(fromActions.onInvalidateCodesSuccessfully, (state, action) => {
    const updatedCoupons = [...state.coupons];
    const updatedIndex = updatedCoupons.findIndex((coupon) => coupon.id === action.payload.id);

    updatedIndex === -1 ? updatedCoupons.push({ ...action.payload }) : (updatedCoupons[updatedIndex] = action.payload);

    return { ...state, coupons: [...updatedCoupons], couponsError: undefined };
  }),
  on(fromActions.onInvalidateCodesFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // archive coupon
  on(fromActions.onArchiveCoupon, (state) => ({ ...state })),
  on(fromActions.onArchiveCouponSuccessfully, (state, action) => {
    const payload = action.payload;
    const updatedCoupons = [...state.coupons];
    const updatedIndex = updatedCoupons.findIndex((coupon) => coupon.id === payload.id);

    updatedIndex === -1 ? updatedCoupons.push({ ...payload }) : (updatedCoupons[updatedIndex] = payload);

    return { ...state, coupons: [...updatedCoupons], couponsError: undefined };
  }),
  on(fromActions.onArchiveCouponFailed, (state, action) => ({ ...state, couponsError: action.payload })),

  // flush coupon errors
  on(fromActions.flushCouponError, (state, action) => {
    return {...state, couponsError:undefined};
  }),

   // fetch coupon details by id
  on(fromActions.onFetchCouponDetailsCouponSuccessfully, (state, action) => {
    const updatedCoupons = [...state.coupons];
    const updatedIndex = updatedCoupons.findIndex((coupon) => coupon.id === action.payload.id);

    if (updatedIndex !== -1) {
      updatedCoupons[updatedIndex] = action.payload;
    }

    return { ...state, coupons: updatedCoupons, couponsError: undefined };
  })

);

export function reducer(state: FeatureState | undefined, action: Action) {
  return couponsReducer(state, action);
}
// Reducer Declarations - END
