import { Component, ViewChild, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import Debug from 'debug';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TwintCouponsProvider } from '../../providers/twint.coupons.provider';
import { map, Observable, take , combineLatest, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CouponDTO, CouponStateEnum, CouponTypeEnum, RedeemStateEnum } from '@modeso/types__twint-lib-coupons';
import { ConfirmDeletionDialog } from '../../dialog/confirmDeletionDialog/confirmDeletion';
import { ConfirmationDialog } from '../../dialog/confirmationDialog/confirmationDialog';
import { CouponErrorDialog } from '@modeso/twint-lib-couponsmng-fe';

const debug = Debug('modeso:twint-admin:ListCouponsComponent');
export interface CouponDTOExtended extends CouponDTO {
  redeemState?: string;
  availableCodes?: number;
}
@Component({
  selector: 'app-list-coupons',
  templateUrl: './list-coupons.component.html',
  styleUrls: ['./list-coupons.component.scss'],
})
export class ListCouponsComponent implements OnInit {
  dataSource = new MatTableDataSource<CouponDTO>();
  displayedColumns: string[] = ['name', 'validFrom', 'validTo', 'numberOfAvailableCoupons', 'maxApplicationPerUser' ,'redeemState', 'activationState','buttons'];
  couponManagement$: Observable<any>;
  getErrors: Subscription;
  @Input() coupons$;
  @Input() hasReadPermission;
  @Input() hasWritePermission;
  @Input() archivedState;
  @Output() archivedStateChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;


  constructor(private router: Router,
              private couponProvider: TwintCouponsProvider,
              public dialog: MatDialog) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    this.couponSubscription();

    this.getErrors = this.couponProvider.getCouponError$().subscribe((httperror) => {
      debug("errors...")
      debug(httperror)
      if (httperror !== null) {
        debug(httperror)
        if(httperror && httperror.error && httperror.error.message ){
          //Show dialog
          this.dialog.open(CouponErrorDialog, {
            data: {
              error: httperror.error.message
            },
          });
        } else {
          if(httperror && httperror.error ){
            //Show general dialoge
            this.dialog.open(CouponErrorDialog, {
              data: {
                error: "Internal Server error"
              },
            });
          }
        }

      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  deleteCoupon(coupon: CouponDTO) {
    const dialogRef = this.dialog.open(ConfirmDeletionDialog, {
      data: {
          message: `Are you sure you want to delete ${coupon.name}?`
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.couponProvider.deleteCoupon(coupon.id);
      }
    });
  }


  editCoupon(couponId:string){
    this.router.navigate([`/de-ch/add-coupon`, { couponId } ]);
  }

  archiveCoupon(couponId,archiveState) {
      archiveState = !archiveState;
      this.archivedStateChanged.emit(archiveState);
      this.couponProvider.archiveCoupon({couponId , archiveState });
  }

  ngOnChanges(changes: SimpleChanges) {

    this.couponSubscription();

  }

  openConfirmationDialog(type: number, text: string , callback: Function) {
    // TYPE 1 ==> (Yes - Cancel) dialog
    // TYPE 2 ==> (Ok) dialog

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: { type, text },
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
      if (result) {
        callback();
      }
    });
  }

  changeCouponState(coupon: CouponDTO) {
    switch (coupon.state) {
      case CouponStateEnum.Active:
        this.couponProvider.deactivateCoupon(coupon.id);
        break;

      case CouponStateEnum.Inactive:
        const dialogText = 'The coupon is about to be published. Do you want to publish the coupon?';
        this.openConfirmationDialog(1, dialogText, ()=>{
          this.couponProvider.activateCoupon(coupon.id);
        });
        break;
    }
  }

  couponListMapper(coupon: CouponDTOExtended){

    coupon.maxApplicationPerUser =  coupon.maxApplicationPerUser && coupon.maxApplicationPerUser !== 0 ?
                                    coupon.maxApplicationPerUser : null;

    coupon.redeemState = "-";
    coupon.availableCodes = this.calculateAvailableCodes(coupon);

    return coupon;

  }

  calculateAvailableCodes(coupon: CouponDTOExtended) {
    let countTotal;

    if (coupon.couponType == CouponTypeEnum.Single) {
        if (coupon.maxApplicationOverAllUsers == 0) {
          return null;
        }
        else {
          countTotal = coupon.maxApplicationOverAllUsers;
        }

    }else if (coupon.couponType == CouponTypeEnum.Multi) {
        countTotal = coupon.codes.length;

      }
    const countAvailable = countTotal - coupon.usedCodes;
    return countAvailable;
  }

  couponSubscription(){
    this.couponManagement$ = combineLatest([this.coupons$]).pipe(
      map(([coupons]) => {
        this.dataSource = new MatTableDataSource<CouponDTO>();
        const dataSource = this.dataSource;
        const couponsMapper = coupons
        .filter((coupon)=> {
          if(this.archivedState){
            return coupon.archived === true;
          }
          return !coupon.archived
        })
        .map((coupon) => {
          const mappedCoupon = { ...coupon };
          return this.couponListMapper(mappedCoupon);
        });
        dataSource.paginator = this.paginator;
        dataSource.data = couponsMapper;
        return dataSource;
      })
    );
  }

  ngOnDestroy(){
    if(this.getErrors){
      this.getErrors.unsubscribe();
      this.couponProvider.flushError();
    }
  }

  public get couponActivationState(): typeof CouponStateEnum {
    return CouponStateEnum;
  }
}
