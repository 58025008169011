import { CouponDTO } from '@modeso/types__twint-lib-coupons';
import { createAction, props, Action } from '@ngrx/store';

export const onAddCoupon = createAction('[TDV Add Coupon] add coupon', props<{ payload: CouponDTO }>());

export const onAddCouponSuccessfully = createAction(
  '[TDV Add Coupon successfully] add coupon successfully',
  props<{ payload: CouponDTO }>()
);

export const onAddCouponFailed = createAction('[TDV Add Coupon failed] add coupon failed', props<{ payload: any }>());

export const onUpdateCoupon = createAction('[TDV Update Coupon] update coupon', props<{ payload: CouponDTO }>());

export const onUpdateCouponSuccessfully = createAction(
  '[TDV Update Coupon successfully] update coupon successfully',
  props<{ payload: CouponDTO }>()
);

export const onUpdateCouponFailed = createAction(
  '[TDV Update Coupon failed] update coupon failed',
  props<{ payload: any }>()
);

export const fetchCouponDetailsById = createAction('[TDV Fetch Coupon Details]', props<{ payload: any }>());

export const onFetchCouponDetailsCouponSuccessfully = createAction(
  '[TDV Fetch Coupon Details successfully]',
  props<{ payload: CouponDTO }>()
);

export const onFetchCouponDetailsFailed = createAction(
  '[TDV Fetch Coupon Details failed]',
  props<{ payload: any }>()
);

export const onGetCoupons = createAction('[TDV Get Coupons] get coupons');

export const onGetCouponsSuccessfully = createAction(
  '[TDV Get Coupons successfully] get coupons successfully',
  props<{ payload: any }>()
);

export const onGetCouponsFailed = createAction(
  '[TDV Get Coupons failed] get coupons failed',
  props<{ payload: any }>()
);

export const onActivateCoupon = createAction('[TDV Activate Coupon] activate coupon', props<{ payload: string }>());

export const onActivateCouponSuccessfully = createAction(
  '[TDV Activate Coupon successfully] activate coupon successfully',
  props<{ payload: CouponDTO }>()
);

export const onActivateCouponFailed = createAction(
  '[TDV Activate Coupon failed] activate coupon failed',
  props<{ payload: any }>()
);

export const onDeactivateCoupon = createAction(
  '[TDV Deactivate Coupon] deactivate coupon',
  props<{ payload: string }>()
);

export const onDeactivateCouponSuccessfully = createAction(
  '[TDV Deactivate Coupon successfully] deactivate coupon successfully',
  props<{ payload: CouponDTO }>()
);

export const onDeactivateCouponFailed = createAction(
  '[TDV Deactivate Coupon failed] deactivate coupon failed',
  props<{ payload: any }>()
);

export const onDeleteCoupon = createAction('[TDV Delete Coupon] delete coupon', props<{ payload: string }>());

export const onDeleteCouponSuccessfully = createAction(
  '[TDV Delete Coupon successfully] delete coupon successfully',
  props<{ payload: string }>()
);

export const onDeleteCouponFailed = createAction(
  '[TDV Delete Coupon failed] delete coupon failed',
  props<{ payload: any }>()
);

export const onInvalidateCode = createAction(
  '[TDV Invalidate Code] invalidate code',
  props<{ payload: { couponId: string; codeId: string } }>()
);

export const onInvalidateCodeSuccessfully = createAction(
  '[TDV Invalidate Code successfully] invalidate code successfully',
  props<{ payload: CouponDTO }>()
);

export const onInvalidateCodeFailed = createAction(
  '[TDV Invalidate Code failed] invalidate code failed',
  props<{ payload: any }>()
);

export const onInvalidateCodes = createAction('[TDV Invalidate Codes] invalidate codes', props<{ payload: string }>());

export const onInvalidateCodesSuccessfully = createAction(
  '[TDV Invalidate Codes successfully] invalidate codes successfully',
  props<{ payload: CouponDTO }>()
);

export const onInvalidateCodesFailed = createAction(
  '[TDV Invalidate Codes failed] invalidate codes failed',
  props<{ payload: any }>()
);

export const onToggleCouponsFeature = createAction(
  '[TDV Toggle Coupon Feature] Toggle Coupon Feature',
  props<{ payload: boolean }>()
);

export const onToggleCouponsFeatureSuccessfully = createAction(
  '[TDV Toggle Coupon Feature successfully] Toggle Coupon Feature successfully',
  props<{ payload: any }>()
);
export const onToggleCouponsFeatureFailed = createAction(
  '[TDV Toggle Coupon Feature failed] Toggle Coupon Feature failed',
  props<{ payload: any }>()
);

export const onCheckCouponsFeature = createAction('[TDV Check Coupon Feature] Check Coupon Feature');
export const onCheckCouponsFeatureSuccessfully = createAction(
  '[TDV Check Coupon Feature successfully] Check Coupon Feature successfully',
  props<{ payload: any }>()
);
export const onCheckCouponsFeatureFailed = createAction(
  '[TDV Check Coupon Feature failed] Check Coupon Feature failed',
  props<{ payload: any }>()
);


export const onArchiveCoupon = createAction('[TDV Archive Coupon] archive coupon', props<{ payload: any }>());

export const onArchiveCouponSuccessfully = createAction(
  '[TDV Archive Coupon successfully] archive coupon successfully',
  props<{ payload: CouponDTO }>()
);

export const onArchiveCouponFailed = createAction(
  '[TDV Archive Coupon failed] archive coupon failed',
  props<{ payload: any }>()
);


export const  flushCouponError =  createAction(
  '[TDV Flush Coupon] Flush Coupon Errors'
);

export interface ActionWithPayload<T> extends Action {
  payload: T;
}
